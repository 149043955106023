import React from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import SEO from "@components/seo"
import PageHeadingTitle from "@components/pageHeadingTitle"

const Products = ({ data }) => {
  const productTypes = data.allSanityProductType.nodes

  return (
    <Layout>
      <SEO
        title="Fabrication Machinery & Equipment | Fabricating Equipment Sales"
        description="Explore top-quality fabrication equipment, including saws, press brakes, lasers, water jets, and more for structural steel and sheet metal manufacturing."
      />
      <div className="page-headline">
        <div className="container">
          <PageHeadingTitle
            h1={"Products"}
            h2={"Fabricating Equipment Sales"}
          />
        </div>
      </div>
      <div className="page-body fesProduct">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-12">
              {/*<h4 className="font-weight-bold text-primary mb-5">
                Product Type
              </h4>*/}
              <div className="side-content text-center">
                <p className="font-weight-bold text-primary h4">
                  Browse by Application
                </p>
                <ul className="list-inline application">
                  <li className="list-inline-item my-2">
                    <Link to="/products">
                      {" "}
                      <span className="app-fontsize px-3 py-2 bg-light rounded-sm">
                        All
                      </span>
                    </Link>
                  </li>
                  {data.allSanityApplication.nodes.map(app => (
                    <li key={app.id} className="list-inline-item my-2">
                      <Link to={`/products/${app.slug.current}`}>
                        <span className="app-fontsize px-3 py-2 bg-light rounded-sm">
                          {app.title}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="row">
                {productTypes.map(type => (
                  <div
                    className="col-md-4 mb-5 text-left"
                    key={type.slug.current}
                  >
                    <div className="blog-item bg-light rounded border">
                      <Link to={`/` + type.slug.current}>
                        <div className="blog-img-tile">
                          <div className="tile-bg-wrapper">
                            <span className="doc-icon">
                              <i className="fa fa-file-text"></i>
                            </span>
                            <div
                              className="tile-bg rounded-top"
                              style={{
                                backgroundImage: `url(${(type &&
                                  type.mainImage &&
                                  type.mainImage.asset &&
                                  type.mainImage.asset.fluid &&
                                  type.mainImage.asset.fluid.src) ||
                                  require("@images/no-image-placeholder.jpg")})`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </Link>
                      <div className="my-3 px-3">
                        <h2 className="h4">
                          <Link to={`/` + type.slug.current} className="h4">
                            {type.title}
                          </Link>
                        </h2>

                        <p className="small mt-2 mb-0">{type.excerpt}</p>
                      </div>
                      <Link
                        to={`/` + type.slug.current}
                        className="btn btn-secondary btn-secondary-red btn-sm m-3"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Products

export const productQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityProductType(sort: { fields: order, order: ASC }) {
      nodes {
        application {
          id
        }
        id
        title
        mainImage {
          asset {
            fluid {
              src
            }
          }
        }
        slug {
          current
        }
      }
    }
    allSanityApplication {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
  }
`
